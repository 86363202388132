<template>
    <div id="about" class="frontpage">
        <HeaderTitle :title="'Sekilas tentang tim di balik Infinity'" :subtitle="'Tentang Kami'" />
        <section>
            <div class="container">
                <Icon icon="bx:bx-search" class="d-none" />
                <div class="col-12 pt-5">
                    <div class="row py-5 align-items-center">
                        <div class="col-md-6 mb-3">
                            <p class="subtitle">Tentang Kami</p>
                            <h2 class="title mb-3">Latar Belakang INFINITY</h2>
                            <p>Berdiri sejak tahun 2021 dengan menggunakan dana Hibah Program Kompetisi Kampus Merdeka, INFINITY sebagai Platform Digital Forum Advisory Board PSMFBE dikembangkan menjadi portal edukasi pendidikan tinggi berbasis komunitas dan forum komunikasi yang memaksimalkan jangkauan dan memastikan sivitas akademika dan para stakeholder tetap aktif dan saling terhubung. <br>
Inilah peran dan tanggung-jawab PSMFBE UBAYA dalam dunia pendidikan tinggi. Harapannya, akan terbentuk kolaborasi strategik dengan para stakeholders, sehingga mahasiswa dapat menggunakan bakat atau potensinya dalam meraih cita-cita menjadi wirausaha, professional, atau peneliti. Kami sadar bahwa sangat penting dalam mempersiapkan atau membekali generasi muda menjadi lulusan dengan karakteristik sebagai pembelajar sejati yang kompeten, lentur dan ulet, dan siap berkontribusi positif dalam pembangunan bangsa serta mampu menjadi warga dunia yang produktif.</p>
                        </div>
                        <div class="col-md-6 mb-3">
                            <img src="@/assets/images/img_about_1.png" alt="IMG ABOUT">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="col-12 mb-3">
                    <div class="row">
                        <div class="col-md-6 d-flex align-items-stretch">
                            <div class="card mb-3 p-4">
                                <p class="subtitle">Visi</p>
                                <h2 class="title mb-3">Visi Dari PSMFBE</h2>
                                <p>Platform Digital yang mampu membangun komunitas, kerjasama dan jejaring dengan institusi dari dalam dan luar negeri bagi pengembangan tridharma perguruan tinggi.</p>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex align-items-stretch">
                            <div class="card mb-3 p-4">
                                <p class="subtitle">Misi</p>
                                <h2 class="title mb-3">Misi Dari PSMFBE</h2>
                                <p>Mengembangkan potensi sivitas akademika melalui penguasaan ilmu ekonomi, manajemen, dan akuntansi sehingga mampu berperan secara sinergis dan signifikan terhadap pengembangan masyarakat bisnis dan industri guna mencapai kualitas hidup yang lebih baik.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section style="background: #F7FAFD;">
            <div class="container">
                <div class="col-12 py-3">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <img src="@/assets/images/img_about_2.png" alt="IMG ABOUT">
                        </div>
                        <div class="col-md-6">
                            <p class="subtitle">Tujuan</p>
                            <h2 class="title mb-3">Tujuan Dari PSMFBE</h2>
                            <p>PSMFBE sebagai Pendidikan Tinggi mampu mengembangkan potensi dan kompetensi seluruh sivitas akademika dalam membangun komunitas, kerjasama dan jejaring dengan institusi dari dalam dan luar negeri bagi pengembangan tridharma perguruan tinggi. Ukuran pencapaian tujuan atau capaian dan raihan terwujud dalam kemampuan sebagai berikut:</p>
                            <ol class="mt-3">
                                <li>1. beradaptasi sesuai kebutuhan dunia industri (adaptif sustainability).</li>
                                <li>2. mewujudkan sinergitas kolaborasi antar stakeholder (synergetic collaborative).</li>
                                <li>3. melakukan pemutakhiran kurikulum selaras jaman (innovation based curriculum).</li>
                                <li>4. melakukan perbaikan berkesinambungan terhadap proses dan siklus pendidikan bisnis dan manajemen</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="col-12 py-3">
                    <div class="row mb-5">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <center>
                                <h2 class="title mb-3">Mengapa Infinity?</h2>
                                <p>Infinity hadir sebagai terobosan pembelajaran baru yang menarik, tanpa terbatas oleh ruang kelas dan jam perkuliahan.</p>
                            </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="clarity:group-line" />
                                    <h3>Berbasis Komunitas</h3>
                                    <p>Belajar akan terasa begitu menyenangkan ketika kita dapat berdiskusi dengan banyak teman</p>
                                </center>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="fluent:chart-person-20-regular" />
                                    <h3>Berdiskusi Langsung dengan Para Pakar</h3>
                                    <p>Infinity menghadirkan para pakar yang siap untuk berdiskusi dan berkoloaborasi bersama.</p>
                                </center>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="cil:book" />
                                    <h3>Mendapatkan Ilmu melampaui Kelas</h3>
                                    <p>Dengan adanya resources yang lengkap, ilmu dapat didapatkan kapanpun dan dimanapun, melampaui kelas kuliah. </p>
                                </center>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 d-flex align-items-stretch">
                            <div class="card item-choose p-3 d-flex align-items-stretch">
                                <center>
                                    <Icon icon="uiw:like-o" />
                                    <h3>Peluang Bekerja & Berkarya</h3>
                                    <p>Infinity merupakan tempat kolaborasi antara mahasiswa, dosen dan pelaku usaha / bisnis di Industri yang sesuai. Peluang untuk berkarya dan mendapatkan pekerjaan akan semakin besar.</p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section style="background: #F7FAFD;">
            <div class="container">
                <div class="col-12 py-3">
                    <center>
                        <p class="subtitle">Team</p>
                        <h2 class="title mb-3">Anggota PSMFBE</h2>
                    </center>
                </div>
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6 col-lg-4 mb-4">
                                <div class="item-team">
                                    <img src="@/assets/images/team/team1.png" alt="TEAM 1">
                                    <div class="item-social">
                                        <ul>
                                            <li><a href="#"><Icon icon="brandico:linkedin" /></a></li>
                                            <li><a href="#"><Icon icon="brandico:facebook-rect" /></a></li>
                                            <li><a href="#"><Icon icon="akar-icons:instagram-fill" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <center>
                                    <p class="mt-3">
                                        <strong class="fs-16">William Wilkson</strong><br>Lorem Ipsum
                                    </p>
                                </center>
                            </div>
                            <div class="col-md-6 col-lg-4 mb-4">
                                <div class="item-team">
                                    <img src="@/assets/images/team/team2.png" alt="TEAM 1">
                                    <div class="item-social">
                                        <ul>
                                            <li><a href="#"><Icon icon="brandico:linkedin" /></a></li>
                                            <li><a href="#"><Icon icon="brandico:facebook-rect" /></a></li>
                                            <li><a href="#"><Icon icon="akar-icons:instagram-fill" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <center>
                                    <p class="mt-3">
                                        <strong class="fs-16">Anita Farma</strong><br>Lorem Ipsum
                                    </p>
                                </center>
                            </div>
                            <div class="col-md-6 col-lg-4 mb-4">
                                <div class="item-team">
                                    <img src="@/assets/images/team/team3.png" alt="TEAM 1">
                                    <div class="item-social">
                                        <ul>
                                            <li><a href="#"><Icon icon="brandico:linkedin" /></a></li>
                                            <li><a href="#"><Icon icon="brandico:facebook-rect" /></a></li>
                                            <li><a href="#"><Icon icon="akar-icons:instagram-fill" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <center>
                                    <p class="mt-3">
                                        <strong class="fs-16">Jesica Jane</strong><br>Lorem Ipsum
                                    </p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section style="background-size: cover;" v-bind:style="{'background-image': 'url(' + require('../../assets/images/banner-about.jpg') + ')'}">
            <div class="container">
                <div class="col-12 py-5">
                    <center>
                        <h2 class="title mb-3 text-white">Info Lebih Lanjut Tentang PSMFBE</h2>
                        <router-link to="/contact-us" class="btn btn-white mt-3 fs-14">Kontak Kami</router-link>
                    </center>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import HeaderTitle from '@/components/HeaderTitle'

    export default {
        name: 'AboutUs',
        components: {
            Icon,
            HeaderTitle
        },
        data () {
            return {
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus tincidunt ipsum tempus viverra sit. Porttitor aliquam tempor, malesuada nulla curabitur consequat lacus aliquam. Rhoncus et elementum, arcu pharetra, nullam sit faucibus. '
            }
        }
    }
</script>
